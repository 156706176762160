export default {
  refund: {
    payment: '支払い',
    refundTuition: '払い戻し可能',
    refundMaterial: '払い戻し可能な学習資料',
    refundMaterialTitle: '払い戻し可能な学習資料',
    refundMaterialTips: '返金は未発送の教材に限ります。ご不明な点がございましたら、{x}までお問い合わせください',
    attachedGood: '添付されたグッズ',
  },
  preclass: '授業前の準備',
  preclassTips1: '',
  preclassTips2: 'をダウンロードして、授業前の準備事項を見る',
  safetyTips: '本決済サービスは、セキュリティ認証を受けた国際決済機関によって提供されております',
  activation: 'activation',
  toBeActivation: 'To be activated',
  shoppingCart: {
    myCart: 'マイカート',
    totalItems: '合計{x}項目',
    deleteItem: 'アイテムを削除',
    cancelText: 'キャンセル',
    confirmDel: '削除を確認',
    deleteText: '削除',
    couponsInvalid: '無効なクーポン',
    payMethod: 'この支払方法は',
    autoDeduction: '自動引き落としです',
    cartDetails: 'カート詳細',
    addToCart: 'カートに入れる',
    noData: 'データなし',
    confirmDelete: 'カートから削除しますか',
    productName: '商品名',
    amtPayable: 'お支払い金額',
    discountAmt: '割引金額',
    amtPaid: '実際のお支払い金額',
    offerDetails: '割引詳細',
    changeOffer: '割引変更',
    additional: '追加',
    cart: 'カート',
    admissionNotice: '学力診断テストに合格しないとこのコースに申し込むことができません。チャンスは {x} 回しかありません',
    cannotCreateOrder: '今の商品に対して注文を作成できません',
    opFrequent: '操作が頻繁すぎます',
    qtyModifiedSuccess: '数が変更しました',
    deleteSuccess: '削除しました',
    valExceed: '{x}を超えてはいけません',
    valLess: '{x}を下回ることができません',
    addedSuccess: '追加しました',
    promoNotice: '一部だけのお客様に対して割引をご提供いたしますので、お会計の際に金額をご確認のほどよろしくお願いします。',
    addedToCartSuccess: 'カートに入れました',
    combinedDiscount: 'セット割引',
    mailingNotice: 'ご購入いただいたコース（商品）にお届け物が含まれているので、アドレスをご確認ください:',
    refundNotice: 'お払い戻しのコースはセットコースなので、 払い戻しを行うと、セット内の他のコースの自動支払い機能がオフになりますので、継続するかどうかをご確認ください。',
  },
  selectedClassDiffers: 'あなたが選択したクラスは、元のクラス{x}とは異なります。時間割を変更しますか？',
  selectedClass: '選択したクラス',
  originalClass: '元のクラス',
  grade: '学年',
  semester: '学期',
  subject: '科目',
  classLevel: 'クラスレベル',
  teachingMethod: '授業方式',
  confirmClassChange: '授業の変更を確認しますか？',
  changeMsg1587: 'もうすぐ授業が始まるため、現在のクラスは変更できません。',
  changeMsg1587Transfer: 'もうすぐ授業が始まるため、クラスを移動することはできません。',
}
