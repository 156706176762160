export default {
  refund: {
    payment: 'Payment ',
    refundTuition: 'Refundable ',
    refundMaterial: 'Refundable learning material(s)',
    refundMaterialTitle: 'Refundable learning material(s)',
    refundMaterialTips: 'Only learning materials that have not been shipped can be applied for a refund. If you have any questions, please contact us at: {x}',
    attachedGood: 'Attached good(s)',
  },
  preclass: 'Pre-class Preparation',
  preclassTips1: 'Please download ',
  preclassTips2: ' to understand the pre-class preparation',
  safetyTips: 'Our payment services are provided by internationally certified and secure payment institutions.',
  activation: 'activation',
  toBeActivation: 'To be activated',
  shoppingCart: {
    myCart: 'My Shopping Cart',
    totalItems: 'Total {x} items',
    deleteItem: 'Delete Item',
    cancelText: 'cancel',
    confirmDel: 'confirm Delete',
    deleteText: 'delete',
    couponsInvalid: 'Invalid Coupon',
    payMethod: 'This payment method is',
    autoDeduction: 'automatic deduction',
    cartDetails: 'Shopping Cart Details',
    addToCart: 'Add to cart',
    noData: 'No data available',
    confirmDelete: 'Are you sure you want to delete from the shopping cart?',
    productName: 'Product Name',
    amtPayable: 'Amount Payable',
    discountAmt: 'Discount Amount',
    amtPaid: 'Amount Paid',
    offerDetails: 'Offer Details',
    changeOffer: 'Change Offer',
    additional: 'Additional',
    cart: 'Shopping Cart',
    admissionNotice: 'Your child needs to pass an admission assessment before enrolling in this course, with only {x} attempts allowed.',
    cannotCreateOrder: 'The current product cannot create an order',
    opFrequent: 'Operation too frequent',
    qtyModifiedSuccess: 'Quantity modified successfully',
    deleteSuccess: 'Deletion successful',
    valExceed: 'Value cannot exceed {x}',
    valLess: 'Value cannot be less than {x}',
    addedSuccess: 'Added successfully',
    promoNotice: 'Current classes only offer promotions to some users, please check the final price at checkout after changing students',
    addedToCartSuccess: 'Added to cart successfully',
    combinedDiscount: 'combined discount',
    mailingNotice: 'The courses (products) you purchased include items for mailing; please confirm your address:',
    refundNotice: 'The refund course you selected is part of a bundled package. Refunding will disable the automatic payment function for other courses in the bundle. Do you wish to continue?',
  },
  selectedClassDiffers: 'The class you selected is different from the original class {x}. Do you want to confirm the schedule change?',
  selectedClass: 'Your chosen class',
  originalClass: 'Your original class',
  grade: 'Grade',
  semester: 'Semester',
  subject: 'Subject',
  classLevel: 'Class Level',
  teachingMethod: 'Teaching Method',
  confirmClassChange: 'Do you confirm the class change?',
  changeMsg1587: 'The class is about to start, and the current session cannot be rescheduled.',
  changeMsg1587Transfer: 'The class is about to start, and transferring classes is not possible.',
}
